import Head from "next/head";
import Image from "next/image";
import { useRouter } from "next/router";
import { NextPage } from "next/types";
import { HiArrowLeft as ArrowLeftIcon } from "react-icons/hi2";

export const ROUTES_404 = [
  {
    Path: "/",
    Title: "Go to Foliko Lite",
  },
  {
    Path: "/dashboard",
    Title: "Go to Foliko Premium",
  },
  {
    Path: "https://synerai.com/contactus",
    Title: "Contact Us",
  },
];

const NotFound: NextPage = () => {
  const router = useRouter();
  const handleNavigation = (path: string) => {
    router.push(path);
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen gap-6 mx-4">
      <Head>
        <meta name="robots" content="noindex" />
      </Head>
      <div className="relative w-full h-56">
        <Image
          src="/img/404/404.webp"
          alt="404-image"
          priority
          fill
          sizes="100vw"
          style={{
            objectFit: "contain",
          }}
        />
      </div>
      <h1 className="text-6xl font-bold">Oops!</h1>
      <h2 className="font-semibold">
        It looks like you landed on the wrong planet.
      </h2>

      <div className="px-8 text-xs text-center text-gray-400">
        404 - Page not found. The page you are looking for does not exist -
        Please check the URL and try again.
      </div>

      <div className="inline-flex flex-wrap justify-center gap-4">
        {ROUTES_404.map((route) => (
          <button
            key={route.Path}
            className="inline-flex items-center flex-shrink-0 gap-2 px-5 py-1 text-white bg-black rounded "
            onClick={() => handleNavigation(route.Path)}
          >
            <ArrowLeftIcon className="w-5 h-5" />
            <h1>{route.Title}</h1>
          </button>
        ))}
      </div>
    </div>
  );
};

export default NotFound;
